<template>
	<div class="about">
		<customerService @close-popup="closePopup" v-if="customerService"></customerService>
		<div class="banner">
			<div class="bannerbox flex">
				<span>为企业提供“技术开发+供应链+平台运营”</span>
				<span class="bannerbox-tit">一站式商业赋能服务</span>
			</div>
			<img src="../assets/img/aboutbanner.png">
			<div class="Cell flex">
				<div class="Cell-ce flex Cell-ce-bor">
					<div class="Cell-ce-tit">80<span style="font-size: 0.15rem;font-weight: 500;">+</span></div>
					<div class="Cell-ce-lab">全品类覆盖</div>
				</div>
				<div class="Cell-ce flex Cell-ce-bor">
					<div class="Cell-ce-tit">5000<span style="font-size: 0.15rem;font-weight: 500;">+</span></div>
					<div class="Cell-ce-lab">知名品牌</div>
				</div>
				<div class="Cell-ce flex Cell-ce-bor">
					<div class="Cell-ce-tit">近100<span style="font-size: 0.15rem;font-weight: 500;">家</span></div>
					<div class="Cell-ce-lab">特渠资源平台</div>
				</div>
				<div class="Cell-ce flex">
					<div class="Cell-ce-tit">100<span style="font-size: 0.15rem;font-weight: 500;">+</span></div>
					<div class="Cell-ce-lab">私域电商平台</div>
				</div>
			</div>
		</div>

		<!-- 公司简介 -->
		<!-- 力瀚网络科技（上海）有限公司 -->
		<!-- <div class="Introduction flex">
			<div class="Introduction-box flex">
				<div class="Introduction-box-L">
					<div class="Introduction-box-L-tit">力瀚网络科技（上海）有限公司</div>
					<div class="Introduction-box-L-lab">
						力瀚网络科技（上海）有限公司是一家具备独立开发实力的企业数据化转型解决方案 提供商，致力于为企业提供“技术开发+供应链+数字营销”一站式赋能服务。
					</div>
					<div class="Introduction-box-L-lab">
						目前旗下有供应链平台、Saas商城、会员营销、小程序开发、智能数据中台、定制解 决方案等产品服务，覆盖传统电商、电商、新零售等多场景，满足不同企业的业 务需求，助力其实现数字化转型升级。
					</div>
					<div class="Introduction-box-L-lab">
						力瀚以专业技术为支撑，打造会员营销系统智能化、MA流程自动化的全生命周期运营 管理体系以及健全完善的产品供应链体系，助力企业以更精准的方式经营会员用户关 系，持续优化全触点营销体验。
					</div>
				</div>
				<div class="Introduction-box-R">
					<video ref="myVideo" muted class="vjs-tech" controls autoplay="autoplay" width="300" height="170" loop>
						<source src="https://lihansy.oss-cn-beijing.aliyuncs.com/video/20211122170724.mp4" type="video/mp4">
					</video>
				</div>
			</div>
		</div> -->

		<div class="Introduction flex">
			<div class="Introduction-box flex">
				<div class="Introduction-box-L">
					<div class="Introduction-box-L-tit">力瀚实业(上海)有限公司</div>
					<div class="Introduction-box-L-lab">
						力瀚实业(上海)有限公司(简称“力翰”)成立于2011年，是一家具备独立研发能力的供应链综合服务商，始终坚持“以客户为中心，以数据为驱动，以技术为支撑，以运营为抓手”，整合供应链上下游资源，为国内外品牌提供渠道对接、销售、代运营等全价值链管理，为企业提供综合采购、员工福利、积分商城、私域赋能等四大业务场景解决方案。
					</div>
					<div class="Introduction-box-L-lab">
						经过多年发展，力瀚已稳健成长为年销售规模超亿的企业。
					</div>
					<div class="Introduction-box-L-lab">
						未来，力瀚将继续深耕供应链领域，志在成为享誉世界的供应链综合服务商，提升品牌赋能，助力企业发展，持续为客户创造价值。
					</div>
				</div>
				<!-- <div class="Introduction-box-R">
					<video ref="myVideo" muted class="vjs-tech" controls autoplay="autoplay" 
						loop>
						<source src="https://lihansy.oss-cn-beijing.aliyuncs.com/video/20211122170724.mp4"
							type="video/mp4">
					</video>
				</div> -->
			</div>
		</div>

		<!-- 服务 -->
		<div class="service">
			<div class="service-Tit">我们的服务</div>
			<div class="service-body flex">
				<div class="service-body-ber">
					<div class="service-body-ber-ell">
						<div class="ber-ell-body">
							<div class="ber-ell-body-tit">电商平台搭建</div>
							<div class="ber-ell-body-lab">
								<span>B2B2C多用户商城系统</span>
								<span style="margin-left: 0.20rem;">S2B2C供应链系统</span>
							</div>
							<div class="ber-ell-body-lab">
								<span>O2O新零售系统</span>
								<span style="margin-left: 0.20rem;">B2C自营商城系统</span>
							</div>
							<div class="ber-ell-body-lab">
								<span>C2C电商系统</span>
								<!-- <span style="margin-left: 0.20rem;">跨境电商系统</span> -->
							</div>
						</div>
						<img class="service-body-ber-ell-img" style="width: 3.00rem;" src="../assets/img/about-service1.png">
					</div>
					<div class="service-body-ber-ell">
						<div class="ber-ell-body ber-ell-body1" style="padding-top: 0.70rem;">
							<div class="ber-ell-body-tit">开放服务平台</div>
							<div class="ber-ell-body-lab">
								<span>企业合作伙伴</span>
								<span style="margin-left: 0.20rem;">品牌合作商</span>
							</div>
							<div class="ber-ell-body-lab">
								<span>技术合作伙伴</span>
								<span style="margin-left: 0.20rem;">商业服务市场</span>
							</div>
						</div>
						<img class="service-body-ber-ell-img" style="width: 3.00rem;" src="../assets/img/about-service3.png">
					</div>
				</div>
				<div class="service-body-cent flex">
					<div class="service-body-cent-c flex">
						<span style="position: absolute; color: #fff;font-size: 0.40rem;">力瀚</span>
						<img class="service-body-cent-c-img" style="width: 2.40rem;height: 2.40rem;" src="../assets/img/about-servicecent.png">
					</div>
				</div>
				<div class="service-body-ber">
					<div class="service-body-ber-ell" style="height: 1.60rem;">
						<div class="ber-ell-body ber-ell-body2" style="padding-top: 0.44rem;">
							<div class="ber-ell-body-tit">定制开发</div>
							<div class="ber-ell-body-lab">
								<span>商城APP定制</span>
								<span style="margin-left: 0.20rem;">企业商城定制</span>
							</div>
							<div class="ber-ell-body-lab">
								<span>企业官网建设</span>
								<span style="margin-left: 0.20rem;">电商小程序定制</span>
							</div>
							<div class="ber-ell-body-lab">
								<span>功能插件开发</span>
								<span style="margin-left: 0.20rem;">品牌官网建设</span>
							</div>
						</div>
						<img class="service-body-ber-ell-img" style="width: 3.00rem;" src="../assets/img/about-service2.png">
					</div>
					<div class="service-body-ber-ell">
						<div class="ber-ell-body ber-ell-body3" style="padding-top: 0.20rem;">
							<div class="ber-ell-body-tit">运营管理体系</div>
							<div class="ber-ell-body-lab">
								<span>营销策划</span>
								<span style="margin-left: 0.20rem;">视觉设计</span>
							</div>
							<div class="ber-ell-body-lab">
								<span>数据分析</span>
								<span style="margin-left: 0.20rem;">新媒体运营</span>
							</div>
							<div class="ber-ell-body-lab">
								<span>售后服务</span>
								<span style="margin-left: 0.20rem;">仓储物流</span>
							</div>
						</div>
						<img class="service-body-ber-ell-img" style="width: 3.00rem;" src="../assets/img/about-service4.png">
					</div>
				</div>
			</div>

			<div class="tit">我们是思考者,更是实干派</div>

			<div class="step">
				<div class="step-box flex">
					<div class="step-box-cell" v-for="(item,index) in steplist">
						<div class="step-box-cell-b" @click="imgindex = index"
							:class="(index == 4 || index == 9) ? 'step-box-cell-b-labnone' : ''">
							<img v-if="imgindex == index"
								style="width: 100%;height: 100%;position: absolute;left: 0;right: 0;"
								src="../assets/img/about-serviceback.png">
							<div class="step-box-cell-t flex">
								<span style="opacity: .2;">{{index >= 9 ? 1 : 0}}</span>
								<span style="color: #fff;">{{index >= 9 ? 0 : index+1}}</span>
							</div>
							<span class="step-box-cell-b-lab">{{item.title}}</span>
						</div>
					</div>
				</div>
				<img src="../assets/img/about-step.png">
			</div>

			<!-- 企业 -->
			<div class="enterprise">
				<div class="enterprise-text flex">累计服务超过<span style="margin:0 0.10rem;color: red;">10000</span>家企业客户</div>
				<div class="enterprise-imgbox">
					<img class="enterprise-imgbox-img" src="../assets/img/about-enterprise1.png">
					<img class="enterprise-imgbox-img" src="../assets/img/about-enterprise2.png">
					<img class="enterprise-imgbox-img" src="../assets/img/about-enterprise3.png">
					<img class="enterprise-imgbox-img" src="../assets/img/about-enterprise4.png">
					<img class="enterprise-imgbox-img" src="../assets/img/about-enterprise5.png">
				</div>
			</div>
		</div>

		<!-- 环境 -->
		<div class="environment">
			<div class="tit">我们的环境</div>
			<div class="environment-imgbox">
				<img style="width: 100%;" src="../assets/img/about-environment.png">
				<img class="environment-imgbox-img1" src="../assets/img/about-environment1.png">
				<img class="environment-imgbox-img2" src="../assets/img/about-environment2.png">
			</div>
		</div>

		<!-- 联系 -->
		<div class="contact">
			<div class="tit">联系我们</div>
			<div class="contact-box flex">
				<div class="map">
					<amap :zoom="18" :center="maplist">
						<amap-marker :position="maplist" />
					</amap>
				</div>
				<!-- <img style="width: 590px;" src="../assets/img/map.png"> -->
				<div class="contact-box-cont">
					<div class="contact-box-cont-t">上海</div>
					<div class="contact-box-cont-c flex">
						<img style="width: 0.15rem" src="../assets/img/position.png">
						<div style="flex: 1;margin-left: 0.15rem;">
							地址：{{mapaddress}}
						</div>
					</div>

					<div class="contact-box-cont-c flex">
						<img style="width: 0.15rem" src="../assets/img/phone-icon.png">
						<div style="flex: 1;margin-left: 0.15rem;">
							电话：021-55150693
						</div>
					</div>

					<div class="contact-box-cont-b" @click="customerService = true">
						商务合作
					</div>
				</div>
			</div>
		</div>

		<div class="honor">
			<div class="tit">资质荣誉</div>
			<div class="honor-box">
				<div class="honor-box-tit flex">
					<div style="flex: 1;height: 50%;border-bottom: 1px solid #E0E0E0;"></div>
					<div style="padding: 0 0.20rem;font-size: 0.20rem;color: #333;font-weight: bold;">证书</div>
					<div style="flex: 1;height: 50%;border-bottom: 1px solid #E0E0E0;"></div>
				</div>

				<div class="honor-box-imgbox flex">
					<img class="honor-box-imgbox-1" style="width: 3.14rem; margin-right: 0.40rem;margin-bottom: 0.20rem;"
						src="../assets/img/honor1.png">
					<img class="honor-box-imgbox-1" style="width: 3.21rem;margin-right: 0.40rem;margin-bottom: 0.20rem;"
						src="../assets/img/honor2.png">
					<img class="honor-box-imgbox-1" style="width: 3.26rem;margin-right: 0.40rem;margin-bottom: 0.20rem;"
						src="../assets/img/honor3.png">
					<img style="width: 2.53rem;margin-right: 0.40rem;margin-bottom: 0.20rem;"
						src="../assets/img/honor4.png">
					<img style="width: 1.08rem;margin-right: 0.40rem;margin-bottom: 0.20rem;"
						src="../assets/img/honor5.png">
					<img style="width: 1.10rem;margin-right: 0.40rem;margin-bottom: 0.20rem;"
						src="../assets/img/honor6.png">
				</div>
			</div>

			<div class="honor-box">
				<div class="honor-box-tit flex">
					<div style="flex: 1;height: 50%;border-bottom: 1px solid #E0E0E0;"></div>
					<div style="padding: 0 0.20rem;font-size: 0.20rem;color: #333;font-weight: bold;">资质认证</div>
					<div style="flex: 1;height: 50%;border-bottom: 1px solid #E0E0E0;"></div>
				</div>

				<div class="honor-box-imgbox flex">
					<div class="honor-box-zlhb-cell flex" style="margin-bottom: 0.60rem;">
						<img class="honor-box-zlhb-cellimg" style="width: 5.44rem;" src="../assets/img/about-qua1.png">
					</div>
					<div class="honor-box-zlhb-cell flex" style="margin-bottom: 0.60rem;">
						<img class="honor-box-zlhb-cellimg" style="width: 5.44rem;" src="../assets/img/about-qua2.png">
					</div>
					<div class="honor-box-zlhb-cell flex" style="margin-bottom: 0.60rem;">
						<img class="honor-box-zlhb-cellimg" style="width: 5.44rem;" src="../assets/img/about-qua3.png">
					</div>
					<div class="honor-box-zlhb-cell flex" style="margin-bottom: 0.60rem;">
						<img class="honor-box-zlhb-cellimg" style="width: 5.44rem;" src="../assets/img/about-qua4.png">
					</div>
				</div>
			</div>

			<div class="honor-box">
				<div class="honor-box-tit flex">
					<div style="flex: 1;height: 50%;border-bottom: 1px solid #E0E0E0;"></div>
					<div style="padding: 0 0.20rem;font-size: 0.20rem;color: #333;font-weight: bold;">荣誉</div>
					<div style="flex: 1;height: 50%;border-bottom: 1px solid #E0E0E0;"></div>
				</div>

				<div class="honor-box-imgbox flex" style="padding: 0.40rem;">
					<div class="honor-box-zlhb-cell flex">
						<img class="about-honorimg" style="width: 3.20rem;" src="../assets/img/about-honor1.png">
					</div>
					<div class="honor-box-zlhb-cell flex">
						<img class="about-honorimg" style="width: 3.20rem;" src="../assets/img/about-honor2.png">
					</div>
					<div class="honor-box-zlhb-cell flex">
						<img class="about-honorimg" style="width: 3.20rem;" src="../assets/img/about-honor3.png">
					</div>
				</div>
			</div>

			<div class="honor-box">
				<div class="honor-box-tit flex">
					<div style="flex: 1;height: 50%;border-bottom: 1px solid #E0E0E0;"></div>
					<div style="padding: 0 0.20rem;font-size: 0.20rem;color: #333;font-weight: bold;">战略合作伙伴</div>
					<div style="flex: 1;height: 50%;border-bottom: 1px solid #E0E0E0;"></div>
				</div>

				<div class="honor-box-zlhb flex honor-box-zlhbbot" style="width: 50%;margin: 0 auto;">
					<div class="honor-box-zlhb-cell flex">
						<img style="max-width: 1.17rem;" src="../assets/img/aly.png">
					</div>
					<div class="honor-box-zlhb-cell flex">
						<img style="max-width: 1.17rem;" src="../assets/img/hw.png">
					</div>
					<div class="honor-box-zlhb-cell flex">
						<img style="max-width: 1.17rem;" src="../assets/img/bd.png">
					</div>
					<div class="honor-box-zlhb-cell flex">
						<img style="max-width: 1.17rem;" src="../assets/img/wxlogo.png">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from "axios";
	import customerService from '@/components/customerService.vue';
	export default {
		data() {
			return {
				imgindex: 0,
				customerService: false,
				mapaddress: '上海市普陀区同普路1030弄天地软件园南园A栋2层',
				maplist: [],
				steplist: [{
						title: '需求沟通'
					},
					{
						title: '落实方案'
					},
					{
						title: '签约付款'
					},
					{
						title: '成立项目组'
					},
					{
						title: '运维部署'
					},
					{
						title: '测试调整'
					},
					{
						title: '测试通过'
					},
					{
						title: '总监审核'
					},
					{
						title: '正式上线'
					},
					{
						title: '持续跟进'
					}
				]
			}
		},
		components:{
			customerService
		},
		created() {
			setTimeout(() => {
				this.$refs.myVideo.play();
			}, 1000);
			this.getMap();
		},
		methods: {
			closePopup(){
				this.customerService = false
			},
			getMap() {
				let that = this;
				axios.get('https://restapi.amap.com/v3/geocode/geo?parameters', {
						params: {
							key: '010138ce644befee594229041b4eddc3',
							address: that.mapaddress
						}
					})
					.then(function(response) {
						let lo = Number(response.data.geocodes[0].location.split(',')[0]);
						let la = Number(response.data.geocodes[0].location.split(',')[1]);
						that.maplist.push(lo,la)
						// console.log(that.maplist );
					})
			}
		}
	}
</script>
<style lang="scss" scoped>
	@media screen and (max-width: 750px) {
		.Introduction-box{
			width: 90% !important;
		}
		.honor-box-imgbox{
			width: 90% !important;
			.honor-box-imgbox-1{
				width: 2.8rem !important;
			}
		}
		.about-honorimg{
			width: 2.8rem !important;
			margin-bottom: 0.2rem !important;
		}
		
		.contact-box-cont-b{
			margin-top: 0.2rem !important;
		}
		
		.honor-box-zlhbbot{
			width: 90% !important;
		}
		
		.honor-box-zlhb-cellimg{
			width: 3.2rem !important;
		}
		
		.Cell{
			width: 90% !important;
		}
		
		
		.ber-ell-body1{
			padding: 0 !important;
			.ber-ell-body-tit{
				margin-top: 0.5rem;
				margin-left: 0.5rem;
			}
			
			.ber-ell-body-lab{
				margin-left: 0.5rem;
			}
		}
		
		.ber-ell-body2{
			padding: 0 !important;
			.ber-ell-body-tit{
				margin-top: 0.3rem;
				margin-left: 0.5rem;
			}
			
			.ber-ell-body-lab{
				margin-left: 0.5rem;
			}
		}
		
		.ber-ell-body3{
			padding: 0 !important;
			.ber-ell-body-tit{
				margin-top: 0.1rem;
				margin-left: 0.5rem;
			}
			
			.ber-ell-body-lab{
				margin-left: 0.5rem;
			}
		}
		
		.service-body{
			width: 100% !important;
		}
		
		.ber-ell-body-lab{
			padding-top: 0.05rem !important;
		}
		
		.service-body-ber-ell-img{
			width: 2.4rem !important;
		}
		
		.service-body-cent{
			width: 2rem !important;
			height: 2rem !important;
			.service-body-cent-c{
				width: 1.6rem !important;
				height: 1.6rem !important;
				.service-body-cent-c-img{
					width: 1.4rem !important;
					height: 1.4rem !important;
				}
			}
		}
		
		.contact-box{
			width: 95% !important;
		}
		
		.map{
			width: 6rem !important;
			height: 3rem !important;
		}
		
		.contact-box-cont{
			height: 3rem !important;
		}
		
		.Introduction{
			height: 5rem !important;
		}
		
		.Introduction-box{
			margin-top: 0 !important;
		}
		
		
		
		.environment-imgbox-img1{
			width: 3rem !important;
			left: 0.8rem !important;
			top: -1rem !important;
		}
		
		.environment-imgbox-img2{
			width: 3.2rem !important;
			right: 1rem !important;
			bottom: -1.4rem !important;
		}
	}
	img {
		width: 100%;
	}

	.tit {
		width: 100%;
		height: 1.00rem;
		line-height: 1rem;
		text-align: center;
		margin-top: 0.60rem;
		font-size: 0.27rem;
		color: #333333;
	}

	.banner {
		width: 100%;
		position: relative;
		z-index: 101;

		.bannerbox {
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 10;
			color: #ccc;
			font-size: 0.20rem;
			line-height: 0.60rem;
			flex-direction: column;

			.bannerbox-tit {
				font-size: 0.45re,;
				color: #fff;
			}
		}

		.Cell {
			width: 60%;
			height: 1.00rem;
			background-color: #fff;
			margin: 0 auto;
			position: absolute;
			bottom: -0.50rem;
			border-radius: 0.10rem;
			left: 0;
			right: 0;
			z-index: 11;

			.Cell-ce {
				flex: 1;
				height: 100%;
				flex-direction: column;

				.Cell-ce-tit {
					font-size: 0.35rem;
					font-weight: bold;
					color: #188CF2;
				}

				.Cell-ce-lab {
					color: #999999;
					font-size: 0.12rem;
				}
			}

			.Cell-ce-bor {
				position: relative;

				&:before {
					content: "";
					width: 0.01rem;
					height: 0.60rem;
					position: absolute;
					right: 0;
					margin-top: -0.30rem;
					top: 50%;
					background: #999999;
					opacity: .3;
				}
			}
		}
	}

	.Introduction {
		width: 100%;
		height: 4.00rem;
		background-color: #f5f5f5;

		.Introduction-box {
			width: 60%;
			height: 2.00rem;
			margin: 0 auto;
			margin-top: 1.00rem;
			justify-content: space-between;

			.Introduction-box-L {
				width: 65%;
				height: 100%;
				text-align: left;

				.Introduction-box-L-tit {
					color: #333333;
					font-size: 0.18rem;

					font-weight: bold;
				}

				.Introduction-box-L-lab {
					font-size: 0.12rem;
					color: #999999;
					margin-top: 0.20rem;
				}
			}

			.Introduction-box-R {
				width: 3.00rem;
				height: 1.70rem;
				position: relative;
				z-index: 103;
				.vjs-tech{
					width: 3rem;
					height: 1.7rem;
				}
				// background-color: #888888;
			}
		}
	}

	.service {
		width: 100%;

		// height: 500px;
		.service-Tit {
			width: 100%;
			height: 1.00rem;
			text-align: center;
			line-height: 1.00rem;
			color: #333333;
			font-size: 0.27rem;
		}

		.service-body {
			// width: 60%;
			height: 4.00rem;
			margin: 0 auto;

			.service-body-ber {
				width: 3.00rem;
				height: 3.00rem;

				.service-body-ber-ell {
					width: 100%;
					height: 1.10rem;
					position: relative;

					.ber-ell-body {
						width: 100%;
						height: 1.30rem;
						position: absolute;
						padding-top: 0.30rem;
						padding-left: 0.30rem;
						text-align: left;
						z-index: 11;
						color: #fff;

						.ber-ell-body-tit {
							font-size: 0.13rem;
						}

						.ber-ell-body-lab {
							font-size: 0.10rem;
							padding-top: 0.10rem;
						}
					}
				}
			}

			.service-body-cent {
				width: 3.00rem;
				height: 3.00rem;
				background-color: #EDEEEF;
				border-radius: 100%;

				.service-body-cent-c {
					width: 2.60rem;
					height: 2.60rem;
					background-color: #F5F6F7;
					border-radius: 100%;
					position: relative;
				}
			}
		}

		.step-box-cell-b-labnone {
			border-right: 0px !important;
		}

		.step {
			width: 100%;
			position: relative;
			z-index: 103;

			.step-box {
				width: 100%;
				height: 100%;
				position: absolute;
				flex-wrap: wrap;
				z-index: 11;

				.step-box-cell {
					width: 20%;
					height: 50%;

					.step-box-cell-b {
						width: 100%;
						height: 100%;
						border-bottom: 1px solid #cccccc20;
						border-right: 1px solid #cccccc20;
						position: relative;

						.step-box-cell-t {
							height: 100%;
							// position: relative;
							letter-spacing: -0.10rem;
							font-size: 0.45rem;
							font-family: Source Han Sans CN;
							font-weight: 800;
							font-style: italic;
							color: #FFFFFF;
							line-height: 0.39rem;
							// opacity: 0.2;
						}



						.step-box-cell-b-lab {
							position: absolute;
							bottom: 0.20rem;
							left: 0.20rem;
							color: #FFFFFF;
							opacity: .8;
							font-size: 0.12rem;
						}
					}
				}
			}
		}

		.enterprise {
			width: 100%;
			// height: 460px;
			margin: 0.60rem 0;
			position: relative;

			.enterprise-text {
				width: 100%;
				height: 100%;
				position: absolute;
				// padding: 60px 0;
				z-index: 11;
				font-size: 0.28rem;
				font-weight: bold;
				color: #333333;
			}

			.enterprise-imgbox {
				width: 80%;
				margin: 0 auto;
				opacity: .6;

				.enterprise-imgbox-img {
					margin-bottom: 0.20rem;
				}
			}
		}
	}

	.environment {
		width: 100%;

		.environment-imgbox {
			width: 100%;
			margin-top: 1.20rem;
			position: relative;

			.environment-imgbox-img1 {
				position: absolute;
				z-index: 11;
				width: 6.08rem;
				left: 3.80rem;
				top: -0.85rem;
			}

			.environment-imgbox-img2 {
				position: absolute;
				z-index: 11;
				width: 5.48rem;
				right: 4.00rem;
				bottom: -1.10rem;
			}

			// height: 305px;
			// background-image: url(../assets/img/about-environment.png);
			// background-size: 100% 100%;
		}
	}

	.contact {
		width: 100%;
		margin-top: 1.00rem;
		padding-bottom: 1.00rem;

		.tit {
			margin-top: 2.00rem;
		}

		.contact-box {
			// width: 50%;
			margin: 0 auto;
			// justify-content: space-between;

			.map {
				width: 8.80rem;
				height: 4.00rem;
			}

			.contact-box-cont {
				width: 3.00rem;
				height: 3.70rem;
				background: #FFFFFF;
				box-shadow: 0px 2px 8px 1px rgba(79, 79, 79, 0.2);
				text-align: left;
				padding: 0.10rem;
				margin-left: 0.10rem;

				.contact-box-cont-t {
					font-size: 0.36rem;
					font-family: Source Han Sans CN;
					font-weight: bold;
					color: #333333;
					line-height: 0.39rem;
					margin-left: 0.10rem;
					margin-top: 0.30rem;
				}

				.contact-box-cont-c {

					font-size: 0.14rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #333333;
					line-height: 0.15rem;
					margin-top: 0.40rem;
				}

				.contact-box-cont-b {
					width: 1.00rem;
					height: 0.40rem;
					margin-top: 0.80rem;
					background-image: url(../assets/img/zxzx.png);
					background-repeat: no-repeat;
					background-size: 100%;
					font-size: 0.12rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 0.38rem;
					text-align: center;
				}
			}
		}
	}

	.honor {
		width: 100%;
		background-color: #F5F5F5;

		.honor-box {
			// width: 50%;
			margin: 0 auto;

			.honor-box-tit {
				width: 100%;
			}

			.honor-box-imgbox {
				// width: 70%;
				width: 12.00rem;
				justify-content: space-between;
				margin: 0 auto;
				flex-wrap: wrap;
				padding: 0.20rem 0;
			}

			.honor-box-zlhb {
				width: 100%;
				padding: 0.20rem 0;

				.honor-box-zlhb-cell {
					flex: 1;
					justify-content: center;
					height: 1.10rem;
					margin-right: 0.20rem;
					background: #FFFFFF;
					box-shadow: 0px 2px 8px 1px rgba(124, 124, 124, 0.2);
					border-radius: 0.10rem;
					
				}
			}
		}
	}
</style>
